<template>
  <table style="width:100%">
    <thead>
      <tr>
        <th
          rowspan="2"
          class="primary--text font-weight-bold"
          v-text="$t('roles.apiList.table.no')"
        />
        <th
          colspan="3"
          class="primary--text text-center font-weight-bold"
          v-text="$t('roles.apiList.table.permissions')"
        />
        <th
          colspan="4"
          class="primary--text text-center font-weight-bold"
          v-text="$t('roles.apiList.table.roles')"
        />
      </tr>
      <tr>
        <th
          class="primary--text font-weight-bold"
          v-text="$t('roles.apiList.table.path')"
        />
        <th
          class="primary--text font-weight-bold"
          v-text="$t('roles.apiList.table.methods')"
        />
        <th
          class="primary--text font-weight-bold"
          v-text="$t('roles.apiList.table.description')"
        />
        <th
          class="text-center primary--text font-weight-bold"
          v-text="roles.root"
        />
        <th
          class="text-center primary--text font-weight-bold"
          v-text="roles.admin"
        />
        <th
          class="text-center primary--text font-weight-bold"
          v-text="roles.manager"
        />
        <th
          class="text-center primary--text font-weight-bold"
          v-text="roles.user"
        />
      </tr>
    </thead>
    <!--  -->
    <tbody
      v-for="(data, index) in apiList"
      :key="index"
    >
      <th
        :rowspan="data.apiDetail.length + 1"
        v-text="data.title"
      />
      <tr
        v-for="(item, i) in data.apiDetail"
        :key="i"
      >
        <td v-text="item.path" />
        <td v-text="item.methods" />
        <td v-text="item.description" />
        <td>
          <v-checkbox
            v-model="item.root"
            disabled
          />
        </td>
        <td>
          <v-checkbox
            v-model="item.admin"
            color="info"
          />
        </td>
        <td>
          <v-checkbox
            v-model="item.managers"
            color="info"
          />
        </td>
        <td>
          <v-checkbox
            v-model="item.user"
            color="info"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  export default {
    name: 'TableApi',
    data () {
      return {
        roles: {
          root: 'Root',
          admin: 'Admin',
          manager: 'Manager',
          user: 'User',
        },
        apiList: [
          {
            title: this.$t('roles.apiList.part.auth'),
            apiDetail: [
              {
                path: '{{api}}/register',
                methods: 'post',
                description: 'register user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/login',
                methods: 'post',
                description: 'login user',
                root: true,
                admin: false,
                managers: true,
                user: true,
              },
              {
                path: '{{api}}/api-key',
                methods: 'get',
                description: 'get new API key',
                root: true,
                admin: false,
                managers: true,
                user: false,
              },
              {
                path: '{{api}}/users/{{userId}}/update_password',
                methods: 'put',
                description: 'change password',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/{{userId}}/verify-account',
                methods: 'patch',
                description: 'user verify register',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/{{email}}/verify-account-again',
                methods: 'get',
                description: 'resend active code',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
            ],
          },
          {
            title: this.$t('roles.apiList.part.roles'),
            apiDetail: [
              {
                path: '{{api}}/roles/all',
                methods: 'get',
                description: 'get all roles',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/roles',
                methods: 'get',
                description: 'get list detail role',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/roles',
                methods: 'post',
                description: 'create role',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/roles/{{roleId}}',
                methods: 'put',
                description: 'update role',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/roles/{{roleId}}',
                methods: 'delete',
                description: 'delete role',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
            ],
          },
          {
            title: this.$t('roles.apiList.part.user'),
            apiDetail: [
              {
                path: '{{api}}/users/',
                methods: 'get',
                description: 'get list all user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/',
                methods: 'post',
                description: 'create user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/{{userId}}',
                methods: 'get',
                description: 'get detail user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/{{userId}}',
                methods: 'put',
                description: 'update user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/{{userId}}',
                methods: 'delete',
                description: 'delete user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/:userId/manage/:appId',
                methods: 'get',
                description: 'filter user use app',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/users/block/{{userId}}',
                methods: 'post',
                description: 'block user',
                root: true,
                admin: true,
                managers: false,
                user: true,
              },
            ],
          },
          {
            title: this.$t('roles.apiList.part.app'),
            apiDetail: [
              {
                path: '{{api}}/apps',
                methods: 'post',
                description: 'create app',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/apps',
                methods: 'get',
                description: 'get list app',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/apps/{{appsId}}',
                methods: 'get',
                description: 'get detail app',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/apps/{{appsId}}',
                methods: 'put',
                description: 'update app',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/apps/{{appsId}}?isHardDelete=true',
                methods: 'delete',
                description: 'block app',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/apps/{{appsId}}',
                methods: 'delete',
                description: 'delete app',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
            ],
          },
          {
            title: this.$t('roles.apiList.part.config'),
            apiDetail: [
              {
                path: '{{api}}/config',
                methods: 'get',
                description: 'get config',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/config',
                methods: 'put',
                description: 'update config',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
            ],
          },
          {
            title: this.$t('roles.apiList.part.alias'),
            apiDetail: [
              {
                path: '{{api}}/alias',
                methods: 'get',
                description: 'get all alias',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/alias',
                methods: 'post',
                description: 'create alias',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/alias/{{aliasId}}',
                methods: 'put',
                description: 'update alias',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
              {
                path: '{{api}}/alias/{{aliasId}}',
                methods: 'delete',
                description: 'delete alias',
                root: true,
                admin: false,
                managers: false,
                user: true,
              },
            ],
          },
        ],
      }
    },
  }
</script>

<style scoped>
table, th, td {
  border: thin solid rgba(0, 0, 0, 0.12);
  border-collapse: collapse;
}
td {
  padding-left: 10px;
}
tr:hover {
  background-color: #eeeeee;
}
</style>
