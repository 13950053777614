<template>
  <v-container
    id="admin-user-view"
    fluid
    tag="section"
  >
    <material-card
      color="primary"
      icon="mdi-api"
      class="mt-15"
    >
      <template #title>
        {{ $t('roles.apiList.breadcrumb.title') }}
      </template>
      <table-api />
    </material-card>
  </v-container>
</template>

<script>
  import TableApi from '../modules/admin/role/components/TableApi'
  export default {
    name: 'AdminRole',
    components: {
      TableApi,
    },
    // Title page
    metaInfo: {
      titleTemplate: 'Admin Role',
    },
  }
</script>
